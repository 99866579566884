<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ text_title }}</h2>
				<div class="save">
					<button
						@click="postBoardConfig"
						class="btn_save"
						:disabled="!is_save"
					>{{  $language.common.complete }}</button>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-50">
			<!-- 내용 -->
			<div class="container">
				<div class="subindex_mainbox">
					<!-- 게시판 -->
					<!-- //게시판 -->
					<!-- 게시판 이름 -->
					<div
						v-show="!is_tv"
						class="input_area"
					>
						<div class="bright_input">
							<label for="cartelDesc">{{  $language.notice.name }}</label>
							<div class="input_utill">
								<input
									v-model="item_board_config.board_name"
									type="text" id="cartelDesc" :placeholder="$language.notice.name_require" maxlength="20"
								>
								<div class="input_utill_area">
									<div
										v-if="item_board_config.board_name"
										class="btn_del"
									>
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											@click="item_board_config.board_name = ''"
											class="active"
										><span class="hide">{{  $language.common.close }}</span></button>
									</div>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ item_board_config.board_name.length }}/20</span>
								</div>
							</div>
						</div>
					</div>
					<!-- //게시판 이름 -->
					<!-- 게시판 권한 설정 -->
					<div
						v-if="false"
						class="input_area"
					>
						<button
							@click="is_126 = true"
							class="input_area_arrow"
						>
							<div class="flex align_items_center justify_content_between">
								<div class="tit mb-0">{{  $language.notice.set_board_authority }}</div>
								<span class="cell_action"><i class="icon icon-arrow-right"></i></span>
							</div>
						</button>
						<p class="input_desc pb-0">
							{{  $language.notice.set_board_authority_message }}
						</p>
					</div>
					<!-- //게시판 권한 설정 -->
					<!-- 게시판 공개 설정 -->
					<div class="input_area">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">{{  $language.notice.set_board_public }} <!-- 게시판 공개 설정 --></div>
							<p class="toggle-group h_unset">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<span
									class="btn-toggle " :class="{ on: item_board_config.release_fg == 'Y'}"
									@click="item_board_config.release_fg = item_board_config.release_fg == 'Y' ? 'N' : 'Y' "
								><em class="hide">{{  $language.common.join_confirm.name }} <!-- 가입 승인--></em></span>
							</p>
						</div>
						<p class="input_desc pb-0">
							{{  $language.cartel.already_joined_member_public }} <!-- 메타마피아 전체 공개 -->
						</p>
					</div>
					<!-- //게시판 공개 설정 -->

					<div class="input_area">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">{{  $language.notice.set_board_authority }} <!-- 게시판 권한 설정 --></div>
						</div>
						<p class="input_desc pb-0">
							{{  $language.notice.set_board_authority_message }} <!-- 게시판 글 읽기 및 작성 권한을 설정합니다. -->
						</p>
					</div>

					<div>
						<ul>
							<li
								v-for="grade in items_grade"
								:key="'grade_' + grade.cartl_member_grade_number"
								class="mt-10 pb-20"
							>
								<div class="input_area">
									<!-- 등급명 -->
									<h2 class="mt-20 itemlist_head col_basic">{{ grade.cartl_member_grade_name}}</h2>

									<ul class=" flex-row justify-space-between">
										<template
											v-for="p in items_permission"
										>
										<li
											v-if="p.code != 'change_fg'"
											:key="'grade_' + grade.cartl_member_grade_number + '_' + p.code"
											class="flex-row justify-space-between items-center"
											@click="setP(grade, p.code)"
										>
											<v-icon
												v-if="grade.cartl_member_div_code == 'CA02500001'"
											>mdi-checkbox-marked-circle</v-icon>

											<template
												v-else
											>
											<v-icon
												v-if="grade[p.code] != 'Y'"
											>mdi-checkbox-blank-circle-outline</v-icon>
											<v-icon
												v-else
												class="color-primary"
											>mdi-checkbox-marked-circle</v-icon>
											</template>

											<!-- 권한 명 -->
											<button class="size-px-16 ml-5">{{  p.name }}</button>
										</li>
										</template>
									</ul>
								</div>

								<div
									v-if="grade.cartl_member_div_code != 'CA02500003'"
									class="bright_checks items-center"
									@click="setP2(grade)"
								>
									<v-icon
										v-if="grade.cartl_member_div_code == 'CA02500001'"
									>mdi-checkbox-marked-circle</v-icon>

									<template
										v-else
									>
										<v-icon
											v-if="grade.admin_authority_fg != 'Y'"
										>mdi-checkbox-blank-circle-outline</v-icon>
										<v-icon
											v-else
											class="color-primary"
										>mdi-checkbox-marked-circle</v-icon>
									</template>
									<button class="size-px-16 ml-5">게시판 권한(사용자 글 삭제 및 이동)</button>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<!-- //내용 -->
		</div>

		<mafia126
			v-if="is_126"
			:user="user"
			:cartel="item_cartel"
			:board_config="item_board_config"

			@click="is_126 = false"
			@cancel="is_126 = false"

			style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color:  white"
		></mafia126>
	</div>
</template>

<script>
import mafia126 from "@/view/Cartel/mafia126"
export default {
	name: 'mafia125'
	, components: { mafia126 }
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.notice.add
				, title: this.$language.notice.add
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {}
			, item_board_config: {
				board_name: ''
				, cartl_number: this.$route.params.idx
				, user: this.user
				, release_fg: 'Y'
				, all_read_fg: true
				, all_write_fg: true
				, board_authority: [
					{ cartl_member_grade_code: 'CA00400001', all_fg: 'Y', reg_fg: 'Y', inquiry_fg: 'Y', change_fg: 'Y', delete_fg: 'Y', moving_fg: 'Y', admin_authority_fg: 'Y'}
					, { cartl_member_grade_code: 'CA00400002', all_fg: 'Y', reg_fg: 'Y', inquiry_fg: 'Y', change_fg: 'Y', delete_fg: 'Y', moving_fg: 'Y', admin_authority_fg: 'Y'}
					, { cartl_member_grade_code: 'CA00400003', all_fg: 'Y', reg_fg: 'Y', inquiry_fg: 'Y', change_fg: 'Y', delete_fg: 'Y', moving_fg: 'N', admin_authority_fg: 'N'}
					, { cartl_member_grade_code: 'CA00400004', all_fg: 'Y', reg_fg: 'Y', inquiry_fg: 'Y', change_fg: 'Y', delete_fg: 'Y', moving_fg: 'N', admin_authority_fg: 'N'}
					, { cartl_member_grade_code: 'CA00400005', all_fg: 'Y', reg_fg: 'Y', inquiry_fg: 'Y', change_fg: 'Y', delete_fg: 'Y', moving_fg: 'N', admin_authority_fg: 'N'}
				]
			}
			, is_126: false
			, items_grade: []
			, items_permission: [
				{ code: 'all_fg', name: this.$language.common.all}
				, { code: 'inquiry_fg', name: this.$language.common.lookup}
				, { code: 'reg_fg', name: this.$language.common.register}
				, { code: 'delete_fg', name: this.$language.common.delete}
			]
		}
	}
	, computed: {
		is_save: function(){
			let t = false
			if(this.item_board_config.board_name != ''){
				t = true
			}
			t = true
			return t
		}
		, text_title: function(){
			let t = this.program.title
			if(this.$route.params.b_id){
				t = this.$language.notice.edit
			}
			return t
		}
		, board_authority: function(){
			return this.items_grade.filter(function(item){

				return item
			})
		}
		, is_tv: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700007'){
				t = true
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})
				if(result.success){
					this.item_board_config = result.data
					this.$set(this.item_board_config, 'board_number', this.$route.params.b_id)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardPermission: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_permission
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})
				if(result.success){
					this.items_grade = result.data.board_authority
					this.items_grade.sort((b, a) => {
						console.log(b, a)
						return 1
					})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, updateBoardConfig: async function(){

		}
		, postBoardConfig: async function(){
			try{
				if(!this.item_board_config.board_name){
					throw this.$language.notice.name_require
				}
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_board_config
				if(this.$route.params.b_id){
					url = this.$api_url.api_path.post_board_config_modify
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_name: this.item_board_config.board_name
						, board_number: this.item_board_config.board_number
						, board_release_fg: this.item_board_config.release_fg
						// , all_write_fg: this.item_board_config.all_write_fg ? 'Y' : 'N'
						// , all_read_fg: this.item_board_config.all_read_fg ? 'Y' : 'N'
						, board_authority: this.board_authority
					}
					, type: true
				})
				if(result.success){
					if(this.is_tv){
						this.$router.back()
					}
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				// this.permission = this.resetPermission
			}
		}
		, setP2: function(obj){
			if(obj.admin_authority_fg == 'Y'){
				this.$set(obj, 'admin_authority_fg', 'N')
			}else{
				this.$set(obj, 'admin_authority_fg', 'Y')
			}
		}
		, setP: function(obj, code){
			if(obj[code] == 'Y'){
				this.$set(obj, code, 'N')
			}else{
				this.$set(obj, code, 'Y')
			}

			if(code == 'all_fg'){
				if(obj[code] == 'Y') {
					this.items_permission.forEach((i) => { this.$set(obj, i.code, 'Y')})
				}else{
					this.items_permission.forEach((i) => { this.$set(obj, i.code, 'N')})
				}
			}else{
				if(obj[code] != 'Y'){
					this.$set(obj, 'all_fg', 'N')
					if(code == 'inquiry_fg'){
						this.$set(obj, 'reg_fg', 'N')
						this.$set(obj, 'delete_fg', 'N')
					}
				}else{
					if(code == 'reg_fg' || code == 'delete_fg'){
						this.$set(obj, 'inquiry_fg', 'Y')
					}
					let t = true
					this.items_permission.forEach((i) => {
						console.log(i.code, obj[i.code])
						if(i.code != 'all_fg'){
							if(obj[i.code] != 'Y'){
								this.$set(obj, 'all_fg', 'N')
								t = false
							}
						}
					})

					if(t){
						this.$set(obj, 'all_fg', 'Y')
					}
				}
			}
		}

		, getCartelGrade: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_open_cartel_member_grade_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_grade = result.data.cartl_member_grade_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getCartelGrade()
		if(this.$route.params.b_id){
			await this.getBoardConfig()
			await this.getBoardPermission()
		}

		console.log('mafia125', this.$route.query)
	}

	, watch: {
		item_board_config: {
			deep: true
			, immediate: true
			, handler: function (call){
				console.log(call)
			}
		}
	}
}
</script>

<style>
	.color-primary { color: var(--blue01) !important;}
</style>